import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";

function Header() {
  const [bgColor, setBgColor] = useState("light");

  useEffect(() => {
    const updateBackground = () => {
      if (window.innerWidth <= 768) {
        setBgColor("danger");
      } else {
        setBgColor("light");
      }
    };

    // Initial check
    updateBackground();

    // Listener for window resize
    window.addEventListener("resize", updateBackground);

    // Cleanup listener
    return () => {
      window.removeEventListener("resize", updateBackground);
    };
  }, []);
  return (
    <React.Fragment>
      {/* Spinner Start */}
      <div
        id="spinner"
        className="show position-fixed translate-middle w-100 vh-100 top-50 start-50 d-flex align-items-center justify-content-center"
      >
        <div className="spinner-grow text-primary" role="status"></div>
      </div>
      {/* Spinner End */}

      {/* Topbar Start */}
      <div
        className="container-fluid py-1 d-none d-md-flex"
        style={{ backgroundColor: "#212529", color: "#fff" }}
      >
        <div className="container d-flex justify-content-between align-items-center">
          <div className="d-flex">
            <small className="me-3">
              <i className="fas fa-map-marker-alt text-warning me-2"></i>
              Lucknow, Uttar Pradesh
            </small>
            <small className="me-3">
              <i className="fas fa-envelope text-warning me-2"></i>
              info@techuptechnology.com
            </small>
          </div>
          <div className="d-flex align-items-center">
            <small className="me-3">Grow Your Business with Us!</small>
            <div className="social-icons">
              <Link
                to="#"
                className="btn btn-sm btn-outline-light rounded-circle me-2"
              >
                <i className="fab fa-facebook-f"></i>
              </Link>
              <Link
                to="#"
                className="btn btn-sm btn-outline-light rounded-circle me-2"
              >
                <i className="fab fa-twitter"></i>
              </Link>
              <Link
                to="#"
                className="btn btn-sm btn-outline-light rounded-circle me-2"
              >
                <i className="fab fa-instagram"></i>
              </Link>
              <Link
                to="#"
                className="btn btn-sm btn-outline-light rounded-circle"
              >
                <i className="fab fa-linkedin-in"></i>
              </Link>
            </div>
          </div>
        </div>
      </div>
      {/* Topbar End */}

      <style>
        {`
      /* Default styling for larger screens */
      .custom-logo {
        font-size: 1.5rem;
        font-weight: bold;
        color: red;
      }

      /* Mobile-specific styling */
      @media (max-width: 768px) {
        .custom-logo {
          font-size: 0.9rem; /* Smaller font size for mobile */
          text-align: center; /* Center text on mobile */
          color: #007bff; /* Optional color change for mobile */
        }
      }
	 /* Center the dropdown content */
.navbar .dropdown-menu {
  left: 50%;
  transform: translateX(-50%);
  width: 650px; /* Adjust as needed */
}

.multi-column-dropdown .row {
  text-align: justify /* Centers the columns horizontally */
}

.navbar .dropdown-menu {
  padding: 20px;
  text-align: center;
  border: 1px solid #ddd;
  background-color: #fff; /* Optional: Adjust background color */
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
}

.multi-column-dropdown h6 {
  font-weight: bold;
  text-align: center; /* Center-align headings */
  margin-bottom: 10px;
}



    `}
      </style>
      <div
        className="container-fluid navbarCOLOR"
        style={{
          background:
            bgColor === "light"
              ? "linear-gradient(45deg, #f8f9fa, #e9ecef)" // Light gradient
              : "linear-gradient(135deg, #6a11cb 0%, #2575fc 100%)", // Info blue gradient for dark mode
          color: bgColor === "light" ? "#000" : "#fff", // Black text for light, white for dark
          borderBottom:
            bgColor === "light" ? "2px solid #e0e0e0" : "2px solid #17a2b8", // Light border for light mode, info blue for dark mode
        }}
      >
        <div className="container">
          <nav className="navbar navbar-dark navbar-expand-lg py-3">
            <Link to="/" className="navbar-brand">
              <h1
                className=" fw-bold d-block custom-logo"
                style={{
                  color: bgColor === "light" ? "black" : "white",
                }}
              >
                Tech
                <span
                  style={{
                    color: bgColor === "light" ? "red" : "red",
                  }}
                >
                  Up
                </span>
                <span
                  style={{
                    color: bgColor === "light" ? "black" : "white",
                  }}
                >
                  Technology
                </span>
              </h1>
            </Link>
            <button
              type="button"
              className="navbar-toggler me-0 text-dark"
              data-bs-toggle="collapse"
              data-bs-target="#navbarCollapse"
            >
              <span className="navbar-toggler-icon "></span>
            </button>
            <div
              className="collapse navbar-collapse bg-transparent"
              id="navbarCollapse"
            >
              <div className="navbar-nav ms-auto mx-xl-auto p-0">
                <Link
                  to="/"
                  className="nav-item nav-link active text-dark"
                  style={{
                    color: "black", // Gold color
                    fontWeight: "bold", // Bold text
                    textShadow: "1px 1px 2px rgba(0, 0, 0, 0.3)", // Optional shadow for better visibility
                    transition: "color 0.3s ease-in-out", // Smooth color transition on hover
                  }}
                  onMouseEnter={(e) => (e.target.style.color = "#ff6347")} // Change to Tomato color on hover
                  onMouseLeave={(e) => (e.target.style.color = "#ffd700")} // Revert back to Gold color
                >
                  Home
                </Link>
                <Link
                  to="/about"
                  className="nav-item nav-link"
                  style={{
                    color: "black", // Gold color
                    fontWeight: "bold", // Bold text
                    textShadow: "1px 1px 2px rgba(0, 0, 0, 0.3)", // Optional shadow for better visibility
                    transition: "color 0.3s ease-in-out", // Smooth color transition on hover
                  }}
                  onMouseEnter={(e) => (e.target.style.color = "#ff6347")} // Change to Tomato color on hover
                  onMouseLeave={(e) => (e.target.style.color = "#ffd700")} // Revert back to Gold color
                >
                  About
                </Link>

                <Link
                  to="/project"
                  className="nav-item nav-link text-dark"
                  style={{
                    color: "black", // Gold color
                    fontWeight: "bold", // Bold text
                    textShadow: "1px 1px 2px rgba(0, 0, 0, 0.3)", // Optional shadow for better visibility
                    transition: "color 0.3s ease-in-out", // Smooth color transition on hover
                  }}
                  onMouseEnter={(e) => (e.target.style.color = "#ff6347")} // Change to Tomato color on hover
                  onMouseLeave={(e) => (e.target.style.color = "#ffd700")} // Revert back to Gold color
                >
                  Projects
                </Link>
                <div className="nav-item dropdown">
                  <Link
                    to="#"
                    className="nav-link dropdown-toggle text-dark"
                    data-bs-toggle="dropdown"
                    style={{
                      color: "black", // Gold color
                      fontWeight: "bold", // Bold text
                      textShadow: "1px 1px 2px rgba(0, 0, 0, 0.3)", // Optional shadow for better visibility
                      transition: "color 0.3s ease-in-out", // Smooth color transition on hover
                    }}
                    onMouseEnter={(e) => (e.target.style.color = "#ff6347")} // Change to Tomato color on hover
                    onMouseLeave={(e) => (e.target.style.color = "#ffd700")} // Revert back to Gold color
                  >
                    Services
                  </Link>
                  <div className="dropdown-menu multi-column-dropdown">
                    <div className="row">
                      <div className="col-md-4">
                        <h6 style={{ textAlign: "justify" }}>
                          Development Services
                        </h6>
                        <ul className="list-unstyled">
                          <li>
                            <Link to="#">Website Development</Link>
                          </li>
                          <li>
                            <Link to="#">App Development</Link>
                          </li>
                          <li>
                            <Link to="#">WordPress Development</Link>
                          </li>
                          <li>
                            <Link to="#">Android Development</Link>
                          </li>
                          <li>
                            <Link to="#">Android UI & UX Design</Link>
                          </li>
                        </ul>
                      </div>
                      <div className="col-md-4">
                        <h6 style={{ textAlign: "justify" }}>
                          Designing Services
                        </h6>
                        <ul className="list-unstyled">
                          <li>
                            <Link to="#">Website Design</Link>
                          </li>
                          <li>
                            <Link to="#">Mobile UI Design</Link>
                          </li>
                          <li>
                            <Link to="#">WordPress Design</Link>
                          </li>
                          <li>
                            <Link to="#">Responsive Web Design</Link>
                          </li>
                          <li>
                            <Link to="#">Graphic Designing</Link>
                          </li>
                        </ul>
                      </div>
                      <div className="col-md-4">
                        <h6 style={{ textAlign: "justify" }}>
                          Marketing Services
                        </h6>
                        <ul className="list-unstyled">
                          <li>
                            <Link to="#">Digital Marketing</Link>
                          </li>
                          <li>
                            <Link to="#">SEO</Link>
                          </li>
                          <li>
                            <Link to="#">SMO</Link>
                          </li>
                          <li>
                            <Link to="#">Email Marketing</Link>
                          </li>
                          <li>
                            <Link to="#">Content Marketing</Link>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
                <Link
                  to="/contact"
                  className="nav-item nav-link text-dark"
                  style={{
                    color: "black", // Gold color
                    fontWeight: "bold", // Bold text
                    textShadow: "1px 1px 2px rgba(0, 0, 0, 0.3)", // Optional shadow for better visibility
                    transition: "color 0.3s ease-in-out", // Smooth color transition on hover
                  }}
                  onMouseEnter={(e) => (e.target.style.color = "#ff6347")} // Change to Tomato color on hover
                  onMouseLeave={(e) => (e.target.style.color = "#ffd700")} // Revert back to Gold color
                >
                  Contact
                </Link>
              </div>
            </div>
            <div className="d-none d-xl-flex flex-shrink-0">
              <div
                id="phone-tada"
                className="d-flex align-items-center justify-content-center me-4"
              >
                <Link
                  to="#"
                  className="position-relative animated tada infinite text-dark"
                >
                  <i className="fa fa-phone-alt text-dark fa-2x"></i>
                  <div
                    className="position-absolute"
                    style={{ top: "-7px", left: "20px" }}
                  >
                    <span>
                      <i className="fa fa-comment-dots text-secondary"></i>
                    </span>
                  </div>
                </Link>
              </div>
              <div className="d-flex flex-column">
                <span className="text-dark">Have any questions?</span>
                <span className="text-dark">Call: +91 9453551208</span>
              </div>
            </div>
          </nav>
        </div>
      </div>

      {/* Navbar End */}
    </React.Fragment>
  );
}

export default Header;
