import React from "react";
import { Link } from "react-router-dom";

function Footer() {
  return (
    <footer
      className="container-fluid footer text-light py-2"
      style={{
        background: "linear-gradient(135deg, #6a11cb 0%, #2575fc 100%)",
      }}
    >
      <div className="container">
        <div className="row g-5">
          {/* Company Info */}
          <div className="col-lg-4 col-md-6 text-start">
            <Link to="/">
              <h1 className="text-white fw-bold">
                Tech<span className="text-warning">Up</span> Technology
              </h1>
            </Link>
            <p className="mt-3">
              Elevating technology solutions with creativity and innovation.
              Contact us to learn more about our services.
            </p>
            <div className="d-flex">
              <a
                href="/"
                className="btn btn-square rounded-circle me-2"
                style={{ backgroundColor: "#3b5998" }}
              >
                <i className="fab fa-facebook-f text-white"></i>
              </a>
              <a
                href="/"
                className="btn btn-square rounded-circle me-2"
                style={{ backgroundColor: "#00acee" }}
              >
                <i className="fab fa-twitter text-white"></i>
              </a>
              <a
                href="/"
                className="btn btn-square rounded-circle me-2"
                style={{ backgroundColor: "#e4405f" }}
              >
                <i className="fab fa-instagram text-white"></i>
              </a>
              <a
                href="/"
                className="btn btn-square rounded-circle"
                style={{ backgroundColor: "#0077b5" }}
              >
                <i className="fab fa-linkedin-in text-white"></i>
              </a>
            </div>
          </div>

          {/* Quick Links */}
          <div className="col-lg-2 col-md-6 text-start ">
            <h3 className="text-warning">Quick Links</h3>
            <ul className="list-unstyled mt-3">
              <li>
                <Link to="/about" className="text-white">
                  About Us
                </Link>
              </li>
              <li>
                <Link to="/services" className="text-white">
                  Our Services
                </Link>
              </li>
              <li>
                <Link to="/contact" className="text-white">
                  Contact Us
                </Link>
              </li>

              <li>
                <Link to="/projects" className="text-white">
                  Projects
                </Link>
              </li>
              <li>
                <Link to="/blog" className="text-white">
                  Blog
                </Link>
              </li>
            </ul>
          </div>

          {/* Contact Info */}
          <div className="col-lg-3 col-md-6 text-start ">
            <h3 className="text-warning">Contact Us</h3>
            <p className="mt-3">
              <i className="fas fa-map-marker-alt text-warning me-2"></i>
              B-21/117, Lucknow Uttar Pradesh, India
            </p>
            <p>
              <i className="fas fa-phone-alt text-warning me-2"></i>
              +91-9453551208
            </p>
            <p>
              <i className="fas fa-envelope text-warning me-2"></i>
              info@techupTechnology.com
            </p>
          </div>

          {/* Feedback Form */}
          <div className="col-lg-3 col-md-6 text-md-start text-center">
            <h3 className="text-warning">Feedback</h3>
            <form>
              <div className="mb-3">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Your Name"
                />
              </div>
              <div className="mb-3">
                <input
                  type="email"
                  className="form-control"
                  placeholder="Your Email"
                />
              </div>
              <div className="mb-3">
                <textarea
                  className="form-control"
                  rows="2"
                  placeholder="Your Message"
                ></textarea>
              </div>
              <button type="submit" className="btn btn-warning w-100">
                Send Message
              </button>
            </form>
          </div>
        </div>
      </div>
      <div className="text-center mt-4">
        <p className="mb-0">© 2024 TechUp Technology. All rights reserved.</p>
      </div>
    </footer>
  );
}

export default Footer;
