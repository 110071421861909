import React from "react";

function Home() {
  return (
    <>
      {/*  <!-- Carousel Start --> */}

      <div class="container-fluid px-0">
        <div id="carouselId" class="carousel slide" data-bs-ride="carousel">
          {/* <ol class="carousel-indicators">
            <li
              data-bs-target="#carouselId"
              data-bs-slide-to="0"
              class="active"
              aria-current="true"
              aria-label="First slide"
            ></li>
            <li
              data-bs-target="#carouselId"
              data-bs-slide-to="1"
              aria-label="Second slide"
            ></li>
          </ol> */}
          <div class="carousel-inner" role="listbox">
            <div class="carousel-item ">
              <img
                src="img/carousel-1.jpg"
                class="img-fluid" /* 
                style={{ maxHeight: "80vh", width: "100%", objectFit: "cover" }} */
                alt="First slide"
              />
              <div class="carousel-caption">
                <div class="container carousel-content">
                  <h6 class="text-white  h1 animated fadeInUp">
                    IT Education & Training Courses
                  </h6>
                  <h1 class="text-secondary d-none d-lg-block display-5 mb-4 animated fadeInRight">
                    RBL Technology Empowering Your Business
                  </h1>
                  {/* <p class="mb-4 text-white fs-5 animated fadeInDown">
                    Lorem ipsum dolor sit amet elit. Sed efficitur quis purus ut
                    interdum. Pellentesque aliquam dolor eget urna ultricies
                    tincidunt.
                  </p>
                  <a href="" class="me-2"><button type="button" class="px-4 py-sm-3 px-sm-5 btn btn-primary rounded-pill carousel-content-btn1 animated fadeInLeft">Read More</button></a>
                                <a href="" class="ms-2"><button type="button" class="px-4 py-sm-3 px-sm-5 btn btn-primary rounded-pill carousel-content-btn2 animated fadeInRight">Contact Us</button></a> */}
                </div>
              </div>
            </div>
            <div class="carousel-item active">
              <img
                src="img/carousel-2.jpg"
                class="img-fluid" /* style={{ maxHeight: "80vh", width: "100%", objectFit: "cover", filter: "none" }} */
                alt="Second slide"
              />
              <div class="carousel-caption">
                <div class="container carousel-content">
                  <h6 class="text-white h1 animated fadeInUp">
                    Best IT Company & No.1 Training Institute
                  </h6>
                  <h1 class="text-secondary display-5 d-none d-lg-block mb-4 animated fadeInLeft">
                    Quality Digital Services You Really Need!
                  </h1>
                  {/* <p class="mb-4 text-white fs-5 animated fadeInDown">Lorem ipsum dolor sit amet elit. Sed efficitur quis purus ut interdum. Pellentesque aliquam dolor eget urna ultricies tincidunt.</p>
                                 <a href="" class="me-2"><button type="button" class="px-4 py-sm-3 px-sm-5 btn btn-primary rounded-pill carousel-content-btn1 animated fadeInLeft">Read More</button></a>
                                <a href="" class="ms-2"><button type="button" class="px-4 py-sm-3 px-sm-5 btn btn-primary rounded-pill carousel-content-btn2 animated fadeInRight">Contact Us</button></a> */}
                </div>
              </div>
            </div>
          </div>
          <button
            class="carousel-control-prev bg-dark  d-md-block d-lg-block"
            type="button"
            data-bs-target="#carouselId"
            data-bs-slide="prev"
          >
            <span class="carousel-control-prev-icon" aria-hidden="true"></span>
            <span class="visually-hidden">Previous</span>
          </button>
          <button
            class="carousel-control-next bg-dark  d-md-block d-lg-block"
            type="button"
            data-bs-target="#carouselId"
            data-bs-slide="next"
          >
            <span class="carousel-control-next-icon" aria-hidden="true"></span>
            <span class="visually-hidden">Next</span>
          </button>
        </div>
      </div>
      {/*  <!-- Carousel End --> */}

      <div
        className="container-fluid py-5 position-relative"
        style={{ backgroundColor: "#f5f7fa", paddingTop: "3rem" }}
      >
        {/* Centered About Us Label */}
        <div className="container">
          <h2
            style={{
              fontSize: "2.5rem",
              fontFamily: "'Poppins', sans-serif", // Stylish font
              color: "black",
              fontWeight: "bold",
              textTransform: "uppercase",
              marginBottom: "2rem",
            }}
          >
            About Us
          </h2>
          <div className="row align-items-center">
            {/* Image Section */}
            <div className="col-lg-6 col-md-12">
              <div
                className="position-relative"
                style={{
                  transform: "scale(1)",
                  transition: "transform 0.5s ease",
                }}
              >
                <img
                  src="img/about-1.jpg"
                  alt="Main"
                  className="img-fluid rounded shadow-lg"
                  style={{
                    position: "relative",
                    zIndex: 2,
                    border: "5px solid #007bff",
                  }}
                />
                <div
                  className="position-absolute rounded-circle"
                  style={{
                    width: "250px",
                    height: "250px",
                    bottom: "-40px",
                    left: "-40px",
                    zIndex: 1,
                    background: "linear-gradient(135deg, #f8f9fa, #e9ecef)",
                    border: "5px solid #007bff",
                  }}
                ></div>
              </div>
            </div>

            {/* About Us Content Section */}
            <div className="col-lg-6 col-md-12 text-center text-lg-start">
              <h1
                className="display-4 mb-4 mt-5"
                style={{
                  background: "linear-gradient(to right, #007bff, #00aaff)",
                  WebkitBackgroundClip: "text",
                  WebkitTextFillColor: "transparent",
                }}
              >
                Who We Are
              </h1>
              <p
                className="lead text-muted"
                style={{
                  fontSize: "1.25rem",
                  lineHeight: "1.75rem",
                  textAlign: "justify",
                }}
              >
                TechUp Technology Agency is driven by a passion for innovation,
                providing exceptional IT solutions tailored to enhance the
                performance and growth of businesses worldwide.
              </p>
              <p style={{ textAlign: "justify" }}>
                Our team of experts specializes in web development, custom
                software, and cloud solutions. With a commitment to excellence,
                we ensure that every project delivers lasting value and a
                transformative impact.
              </p>
              <a
                href="#"
                className="btn btn-outline-primary rounded-pill px-5 py-3"
                style={{
                  border: "2px solid #007bff",
                  color: "#007bff",
                  textDecoration: "none",
                }}
                onMouseEnter={(e) =>
                  (e.target.style.backgroundColor = "#007bff")
                }
                onMouseLeave={(e) => {
                  e.target.style.backgroundColor = "transparent";
                  e.target.style.color = "#007bff";
                }}
              >
                Learn More
              </a>
            </div>
          </div>
        </div>
      </div>
      {/* services section */}
      <div className="container-fluid services py-5 mb-5">
        <div className="container">
          <div
            className="text-center mx-auto pb-5 wow fadeIn"
            data-wow-delay=".3s"
            style={{
              maxWidth: "800px",
              background: "linear-gradient(to right, #6a11cb, #2575fc)", // Gradient background
              padding: "10px",
              borderRadius: "10px",
              boxShadow: "0 4px 15px rgba(0, 0, 0, 0.1)",
            }}
          >
            <h2
              style={{
                fontSize: "3rem",
                fontFamily: "'Poppins', sans-serif",
                color: "#fff",
                fontWeight: "900",
                textTransform: "uppercase",
                letterSpacing: "5px",
                marginBottom: "1rem",
                textShadow: "2px 2px 5px rgba(0, 0, 0, 0.3)",
              }}
            >
              Training Courses
            </h2>
            <h1
              style={{
                fontSize: "2rem",
                fontFamily: "'Montserrat', sans-serif",
                color: "#f8f9fa",
                fontWeight: "600",
                textTransform: "uppercase",
                letterSpacing: "3px",
                background: "-webkit-linear-gradient(#ff9a9e, #fad0c4)",
                WebkitBackgroundClip: "text",
                WebkitTextFillColor: "transparent",
                marginTop: "0.5rem",
              }}
            >
              With a 100% Job Guarantee
            </h1>
          </div>

          <div className="row g-5 services-inner mt-1">
            <div className="col-md-6 col-lg-4 wow fadeIn" data-wow-delay=".3s">
              <div className="services-item bg-light">
                <div className="p-4 text-center services-content">
                  <div className="services-content-icon">
                    <img
                      src="img\about-1.jpg"
                      alt="Programming Service"
                      style={{
                        width: "100%",
                        height: "200px",
                        objectFit: "cover",
                      }}
                    />
                    <h4 className="mb-3">Web Design</h4>
                    <p className="mb-4">
                      Lorem ipsum dolor sit amet elit. Sed efficitur quis purus
                      ut interdum. Aliquam dolor eget urna ultricies tincidunt.
                    </p>
                    <a
                      href="#"
                      className="btn btn-secondary text-white px-5 py-3 rounded-pill"
                    >
                      Read More
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-lg-4 wow fadeIn" data-wow-delay=".5s">
              <div className="services-item bg-light">
                <div className="p-4 text-center services-content">
                  <div className="services-content-icon">
                    <img
                      src="img\about-1.jpg"
                      alt="Programming Service"
                      style={{
                        width: "100%",
                        height: "200px",
                        objectFit: "cover",
                      }}
                    />
                    <h4 className="mb-3">Web Development</h4>
                    <p className="mb-4">
                      Lorem ipsum dolor sit amet elit. Sed efficitur quis purus
                      ut interdum. Aliquam dolor eget urna ultricies tincidunt.
                    </p>
                    <a
                      href="#"
                      className="btn btn-secondary text-white px-5 py-3 rounded-pill"
                    >
                      Read More
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-lg-4 wow fadeIn" data-wow-delay=".7s">
              <div className="services-item bg-light">
                <div className="p-4 text-center services-content">
                  <div className="services-content-icon">
                    <img
                      src="img\about-1.jpg"
                      alt="Programming Service"
                      style={{
                        width: "100%",
                        height: "200px",
                        objectFit: "cover",
                      }}
                    />
                    <h4 className="mb-3">UI/UX Design</h4>
                    <p className="mb-4">
                      Lorem ipsum dolor sit amet elit. Sed efficitur quis purus
                      ut interdum. Aliquam dolor eget urna ultricies tincidunt.
                    </p>
                    <a
                      href="#"
                      className="btn btn-secondary text-white px-5 py-3 rounded-pill"
                    >
                      Read More
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-lg-4 wow fadeIn" data-wow-delay=".3s">
              <div className="services-item bg-light">
                <div className="p-4 text-center services-content">
                  <div className="services-content-icon">
                    {/* <i className="fas fa-user-secret fa-7x mb-4 text-primary"></i> */}
                    <img
                      src="img\about-1.jpg"
                      alt="Programming Service"
                      style={{
                        width: "100%",
                        height: "200px",
                        objectFit: "cover",
                      }}
                    />
                    <h4 className="mb-3">Web Security</h4>
                    <p className="mb-4">
                      Lorem ipsum dolor sit amet elit. Sed efficitur quis purus
                      ut interdum. Aliquam dolor eget urna ultricies tincidunt.
                    </p>
                    <a
                      href="#"
                      className="btn btn-secondary text-white px-5 py-3 rounded-pill"
                    >
                      Read More
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-lg-4 wow fadeIn" data-wow-delay=".5s">
              <div className="services-item bg-light">
                <div className="p-4 text-center services-content">
                  <div className="services-content-icon">
                    <img
                      src="img\about-1.jpg"
                      alt="Programming Service"
                      style={{
                        width: "100%",
                        height: "200px",
                        objectFit: "cover",
                      }}
                    />
                    <h4 className="mb-3">Digital Marketing</h4>
                    <p className="mb-4">
                      Lorem ipsum dolor sit amet elit. Sed efficitur quis purus
                      ut interdum. Aliquam dolor eget urna ultricies tincidunt.
                    </p>
                    <a
                      href="#"
                      className="btn btn-secondary text-white px-5 py-3 rounded-pill"
                    >
                      Read More
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-lg-4 wow fadeIn" data-wow-delay=".7s">
              <div className="services-item bg-light">
                <div className="p-4 text-center services-content">
                  <div className="services-content-icon">
                    <img
                      src="img\about-1.jpg"
                      alt="Programming Service"
                      style={{
                        width: "100%",
                        height: "200px",
                        objectFit: "cover",
                      }}
                    />
                    <h4 className="mb-3">Programming</h4>
                    <p className="mb-4">
                      Lorem ipsum dolor sit amet elit. Sed efficitur quis purus
                      ut interdum. Aliquam dolor eget urna ultricies tincidunt.
                    </p>
                    <a
                      href="#"
                      className="btn btn-secondary text-white px-5 py-3 rounded-pill"
                    >
                      Read More
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container-fluid project py-5 mb-5">
        <div className="container">
          <div
            className="text-center mx-auto pb-5 wow fadeIn"
            data-wow-delay=".3s"
            style={{ maxWidth: "600px" }}
          >
            <h5 className="text-primary">Our Project</h5>
            <h1>Our Recently Completed Projects</h1>
          </div>
          <div className="row g-5">
            <div className="col-md-6 col-lg-4 wow fadeIn" data-wow-delay=".3s">
              <div className="project-item">
                <div className="project-img">
                  <img
                    src="img/project-1.jpg"
                    className="img-fluid w-100 rounded"
                    alt=""
                  />
                  <div className="project-content">
                    <a href="#" className="text-center">
                      <h4 className="text-secondary">Web Design</h4>
                      <p className="m-0 text-white">Web Analysis</p>
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-lg-4 wow fadeIn" data-wow-delay=".5s">
              <div className="project-item">
                <div className="project-img">
                  <img
                    src="img/project-2.jpg"
                    className="img-fluid w-100 rounded"
                    alt=""
                  />
                  <div className="project-content">
                    <a href="#" className="text-center">
                      <h4 className="text-secondary">Cyber Security</h4>
                      <p className="m-0 text-white">Cyber Security Core</p>
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-lg-4 wow fadeIn" data-wow-delay=".7s">
              <div className="project-item">
                <div className="project-img">
                  <img
                    src="img/project-3.jpg"
                    className="img-fluid w-100 rounded"
                    alt=""
                  />
                  <div className="project-content">
                    <a href="#" className="text-center">
                      <h4 className="text-secondary">Mobile Info</h4>
                      <p className="m-0 text-white">Upcoming Phone</p>
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-lg-4 wow fadeIn" data-wow-delay=".3s">
              <div className="project-item">
                <div className="project-img">
                  <img
                    src="img/project-4.jpg"
                    className="img-fluid w-100 rounded"
                    alt=""
                  />
                  <div className="project-content">
                    <a href="#" className="text-center">
                      <h4 className="text-secondary">Web Development</h4>
                      <p className="m-0 text-white">Web Analysis</p>
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-lg-4 wow fadeIn" data-wow-delay=".5s">
              <div className="project-item">
                <div className="project-img">
                  <img
                    src="img/project-5.jpg"
                    className="img-fluid w-100 rounded"
                    alt=""
                  />
                  <div className="project-content">
                    <a href="#" className="text-center">
                      <h4 className="text-secondary">Digital Marketing</h4>
                      <p className="m-0 text-white">Marketing Analysis</p>
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-lg-4 wow fadeIn" data-wow-delay=".7s">
              <div className="project-item">
                <div className="project-img">
                  <img
                    src="img/project-6.jpg"
                    className="img-fluid w-100 rounded"
                    alt=""
                  />
                  <div className="project-content">
                    <a href="#" className="text-center">
                      <h4 className="text-secondary">Keyword Research</h4>
                      <p className="m-0 text-white">Keyword Analysis</p>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/*   <!-- Team Start --> */}
      <div class="container-fluid py-5 mb-5 team">
        <div class="container">
          <div
            class="text-center mx-auto pb-5 wow fadeIn"
            data-wow-delay=".3s"
            style={{ maxWidth: "600px" }}
          >
            <h5 class="text-primary">Our Team</h5>
            <h1>Meet our expert Team</h1>
          </div>
          <div
            class="owl-carousel team-carousel wow fadeIn"
            data-wow-delay=".5s"
          >
            <div class="rounded team-item">
              <div class="team-content">
                <div class="team-img-icon">
                  <div class="team-img rounded-circle">
                    <img
                      src="img/team-1.jpg"
                      class="img-fluid w-100 rounded-circle"
                      alt=""
                    />
                  </div>
                  <div class="team-name text-center py-3">
                    <h4 class="">Full Name</h4>
                    <p class="m-0">Designation</p>
                  </div>
                  <div class="team-icon d-flex justify-content-center pb-4">
                    <a
                      class="btn btn-square btn-secondary text-white rounded-circle m-1"
                      href=""
                    >
                      <i class="fab fa-facebook-f"></i>
                    </a>
                    <a
                      class="btn btn-square btn-secondary text-white rounded-circle m-1"
                      href=""
                    >
                      <i class="fab fa-twitter"></i>
                    </a>
                    <a
                      class="btn btn-square btn-secondary text-white rounded-circle m-1"
                      href=""
                    >
                      <i class="fab fa-instagram"></i>
                    </a>
                    <a
                      class="btn btn-square btn-secondary text-white rounded-circle m-1"
                      href=""
                    >
                      <i class="fab fa-linkedin-in"></i>
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div class="rounded team-item">
              <div class="team-content">
                <div class="team-img-icon">
                  <div class="team-img rounded-circle">
                    <img
                      src="img/team-2.jpg"
                      class="img-fluid w-100 rounded-circle"
                      alt=""
                    />
                  </div>
                  <div class="team-name text-center py-3">
                    <h4 class="">Full Name</h4>
                    <p class="m-0">Designation</p>
                  </div>
                  <div class="team-icon d-flex justify-content-center pb-4">
                    <a
                      class="btn btn-square btn-secondary text-white rounded-circle m-1"
                      href=""
                    >
                      <i class="fab fa-facebook-f"></i>
                    </a>
                    <a
                      class="btn btn-square btn-secondary text-white rounded-circle m-1"
                      href=""
                    >
                      <i class="fab fa-twitter"></i>
                    </a>
                    <a
                      class="btn btn-square btn-secondary text-white rounded-circle m-1"
                      href=""
                    >
                      <i class="fab fa-instagram"></i>
                    </a>
                    <a
                      class="btn btn-square btn-secondary text-white rounded-circle m-1"
                      href=""
                    >
                      <i class="fab fa-linkedin-in"></i>
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div class="rounded team-item">
              <div class="team-content">
                <div class="team-img-icon">
                  <div class="team-img rounded-circle">
                    <img
                      src="img/team-3.jpg"
                      class="img-fluid w-100 rounded-circle"
                      alt=""
                    />
                  </div>
                  <div class="team-name text-center py-3">
                    <h4 class="">Full Name</h4>
                    <p class="m-0">Designation</p>
                  </div>
                  <div class="team-icon d-flex justify-content-center pb-4">
                    <a
                      class="btn btn-square btn-secondary text-white rounded-circle m-1"
                      href=""
                    >
                      <i class="fab fa-facebook-f"></i>
                    </a>
                    <a
                      class="btn btn-square btn-secondary text-white rounded-circle m-1"
                      href=""
                    >
                      <i class="fab fa-twitter"></i>
                    </a>
                    <a
                      class="btn btn-square btn-secondary text-white rounded-circle m-1"
                      href=""
                    >
                      <i class="fab fa-instagram"></i>
                    </a>
                    <a
                      class="btn btn-square btn-secondary text-white rounded-circle m-1"
                      href=""
                    >
                      <i class="fab fa-linkedin-in"></i>
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div class="rounded team-item">
              <div class="team-content">
                <div class="team-img-icon">
                  <div class="team-img rounded-circle">
                    <img
                      src="img/team-4.jpg"
                      class="img-fluid w-100 rounded-circle"
                      alt=""
                    />
                  </div>
                  <div class="team-name text-center py-3">
                    <h4 class="">Full Name</h4>
                    <p class="m-0">Designation</p>
                  </div>
                  <div class="team-icon d-flex justify-content-center pb-4">
                    <a
                      class="btn btn-square btn-secondary text-white rounded-circle m-1"
                      href=""
                    >
                      <i class="fab fa-facebook-f"></i>
                    </a>
                    <a
                      class="btn btn-square btn-secondary text-white rounded-circle m-1"
                      href=""
                    >
                      <i class="fab fa-twitter"></i>
                    </a>
                    <a
                      class="btn btn-square btn-secondary text-white rounded-circle m-1"
                      href=""
                    >
                      <i class="fab fa-instagram"></i>
                    </a>
                    <a
                      class="btn btn-square btn-secondary text-white rounded-circle m-1"
                      href=""
                    >
                      <i class="fab fa-linkedin-in"></i>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/*  <!-- Team End --> */}

      {/* 
        <!-- Testimonial Start --> */}
      <div class="container-fluid testimonial py-5 mb-5">
        <div class="container">
          <div
            class="text-center mx-auto pb-5 wow fadeIn"
            data-wow-delay=".3s"
            style={{ maxWidth: "600px" }}
          >
            <h5 class="text-primary">Our Testimonial</h5>
            <h1>Our Client Saying!</h1>
          </div>
          <div
            class="owl-carousel testimonial-carousel wow fadeIn"
            data-wow-delay=".5s"
          >
            <div class="testimonial-item border p-4">
              <div class="d-flex align-items-center">
                <div class="">
                  <img src="img/testimonial-1.jpg" alt="" />
                </div>
                <div class="ms-4">
                  <h4 class="text-secondary">Client Name</h4>
                  <p class="m-0 pb-3">Profession</p>
                  <div class="d-flex pe-5">
                    <i class="fas fa-star me-1 text-primary"></i>
                    <i class="fas fa-star me-1 text-primary"></i>
                    <i class="fas fa-star me-1 text-primary"></i>
                    <i class="fas fa-star me-1 text-primary"></i>
                    <i class="fas fa-star me-1 text-primary"></i>
                  </div>
                </div>
              </div>
              <div class="border-top mt-4 pt-3">
                <p class="mb-0">
                  Lorem ipsum dolor sit amet elit. Sed efficitur quis purus ut
                  interdum aliquam dolor eget urna. Nam volutpat libero sit amet
                  leo cursus, ac viverra eros morbi quis quam mi.
                </p>
              </div>
            </div>
            <div class="testimonial-item border p-4">
              <div class=" d-flex align-items-center">
                <div class="">
                  <img src="img/testimonial-2.jpg" alt="" />
                </div>
                <div class="ms-4">
                  <h4 class="text-secondary">Client Name</h4>
                  <p class="m-0 pb-3">Profession</p>
                  <div class="d-flex pe-5">
                    <i class="fas fa-star me-1 text-primary"></i>
                    <i class="fas fa-star me-1 text-primary"></i>
                    <i class="fas fa-star me-1 text-primary"></i>
                    <i class="fas fa-star me-1 text-primary"></i>
                    <i class="fas fa-star me-1 text-primary"></i>
                  </div>
                </div>
              </div>
              <div class="border-top mt-4 pt-3">
                <p class="mb-0">
                  Lorem ipsum dolor sit amet elit. Sed efficitur quis purus ut
                  interdum aliquam dolor eget urna. Nam volutpat libero sit amet
                  leo cursus, ac viverra eros morbi quis quam mi.
                </p>
              </div>
            </div>
            <div class="testimonial-item border p-4">
              <div class=" d-flex align-items-center">
                <div class="">
                  <img src="img/testimonial-3.jpg" alt="" />
                </div>
                <div class="ms-4">
                  <h4 class="text-secondary">Client Name</h4>
                  <p class="m-0 pb-3">Profession</p>
                  <div class="d-flex pe-5">
                    <i class="fas fa-star me-1 text-primary"></i>
                    <i class="fas fa-star me-1 text-primary"></i>
                    <i class="fas fa-star me-1 text-primary"></i>
                    <i class="fas fa-star me-1 text-primary"></i>
                    <i class="fas fa-star me-1 text-primary"></i>
                  </div>
                </div>
              </div>
              <div class="border-top mt-4 pt-3">
                <p class="mb-0">
                  Lorem ipsum dolor sit amet elit. Sed efficitur quis purus ut
                  interdum aliquam dolor eget urna. Nam volutpat libero sit amet
                  leo cursus, ac viverra eros morbi quis quam mi.
                </p>
              </div>
            </div>
            <div class="testimonial-item border p-4">
              <div class=" d-flex align-items-center">
                <div class="">
                  <img src="img/testimonial-4.jpg" alt="" />
                </div>
                <div class="ms-4">
                  <h4 class="text-secondary">Client Name</h4>
                  <p class="m-0 pb-3">Profession</p>
                  <div class="d-flex pe-5">
                    <i class="fas fa-star me-1 text-primary"></i>
                    <i class="fas fa-star me-1 text-primary"></i>
                    <i class="fas fa-star me-1 text-primary"></i>
                    <i class="fas fa-star me-1 text-primary"></i>
                    <i class="fas fa-star me-1 text-primary"></i>
                  </div>
                </div>
              </div>
              <div class="border-top mt-4 pt-3">
                <p class="mb-0">
                  Lorem ipsum dolor sit amet elit. Sed efficitur quis purus ut
                  interdum aliquam dolor eget urna. Nam volutpat libero sit amet
                  leo cursus, ac viverra eros morbi quis quam mi.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* 
        <!-- Testimonial End --> */}

      {/*  <!-- Contact Start --> */}
      <div class="container-fluid py-5 mb-5 d-none">
        <div class="container">
          <div
            class="text-center mx-auto pb-5 wow fadeIn"
            data-wow-delay=".3s"
            style={{ maxWidth: "600px" }}
          >
            <h5 class="text-primary">Get In Touch</h5>
            <h1 class="mb-3">Contact for any query</h1>
            <p class="mb-2">
              The contact form is currently inactive. Get a functional and
              working contact form with Ajax & PHP in a few minutes. Just copy
              and paste the files, add a little code and you're done.{" "}
              <a href="https://htmlcodex.com/contact-form">Download Now</a>.
            </p>
          </div>
          <div class="contact-detail position-relative p-5">
            <div class="row g-5 mb-5 justify-content-center">
              <div class="col-xl-4 col-lg-6 wow fadeIn" data-wow-delay=".3s">
                <div class="d-flex bg-light p-3 rounded">
                  <div
                    class="flex-shrink-0 btn-square bg-secondary rounded-circle"
                    style={{ width: "64px", height: "64px" }}
                  >
                    <i class="fas fa-map-marker-alt text-white"></i>
                  </div>
                  <div class="ms-3">
                    <h4 class="text-primary">Address</h4>
                    <a
                      href="https://goo.gl/maps/Zd4BCynmTb98ivUJ6"
                      target="_blank"
                      class="h5"
                    >
                      23 rank Str, NY
                    </a>
                  </div>
                </div>
              </div>
              <div class="col-xl-4 col-lg-6 wow fadeIn" data-wow-delay=".5s">
                <div class="d-flex bg-light p-3 rounded">
                  <div
                    class="flex-shrink-0 btn-square bg-secondary rounded-circle"
                    style={{ width: "64px", height: "64px" }}
                  >
                    <i class="fa fa-phone text-white"></i>
                  </div>
                  <div class="ms-3">
                    <h4 class="text-primary">Call Us</h4>
                    <a class="h5" href="tel:+0123456789" target="_blank">
                      +012 3456 7890
                    </a>
                  </div>
                </div>
              </div>
              <div class="col-xl-4 col-lg-6 wow fadeIn" data-wow-delay=".7s">
                <div class="d-flex bg-light p-3 rounded">
                  <div
                    class="flex-shrink-0 btn-square bg-secondary rounded-circle"
                    style={{ width: "64px", height: "64px" }}
                  >
                    <i class="fa fa-envelope text-white"></i>
                  </div>
                  <div class="ms-3">
                    <h4 class="text-primary">Email Us</h4>
                    <a
                      class="h5"
                      href="mailto:info@example.com"
                      target="_blank"
                    >
                      info@example.com
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div class="row g-5">
              <div class="col-lg-6 wow fadeIn" data-wow-delay=".3s">
                <div class="p-5 h-100 rounded contact-map">
                  <iframe
                    class="rounded w-100 h-100"
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3025.4710403339755!2d-73.82241512404069!3d40.685622471397615!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89c26749046ee14f%3A0xea672968476d962c!2s123rd%20St%2C%20Queens%2C%20NY%2C%20USA!5e0!3m2!1sen!2sbd!4v1686493221834!5m2!1sen!2sbd"
                    style={{ border: "0" }}
                    allowfullscreen=""
                    loading="lazy"
                    referrerpolicy="no-referrer-when-downgrade"
                  ></iframe>
                </div>
              </div>
              <div class="col-lg-6 wow fadeIn" data-wow-delay=".5s">
                <div class="p-5 rounded contact-form">
                  <div class="mb-4">
                    <input
                      type="text"
                      class="form-control border-0 py-3"
                      placeholder="Your Name"
                    />
                  </div>
                  <div class="mb-4">
                    <input
                      type="email"
                      class="form-control border-0 py-3"
                      placeholder="Your Email"
                    />
                  </div>
                  <div class="mb-4">
                    <input
                      type="text"
                      class="form-control border-0 py-3"
                      placeholder="Project"
                    />
                  </div>
                  <div class="mb-4">
                    <textarea
                      class="w-100 form-control border-0 py-3"
                      rows="6"
                      cols="10"
                      placeholder="Message"
                    ></textarea>
                  </div>
                  <div class="text-start">
                    <button
                      class="btn bg-primary text-white py-3 px-5"
                      type="button"
                    >
                      Send Message
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* 
        <!-- Contact End -->
 */}
    </>
  );
}

export default Home;
